
































































import {AboutUsLinkInterface} from '@/shared/interfaces/store/homepage/about-us-link.interface';
import {HomepageStore} from '@/store/modules/homepage';
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class HomeAboutUs extends Vue {

  get cards(): AboutUsLinkInterface[] {
    return HomepageStore.aboutUsLinks;
  };

  get title(): string {
    return HomepageStore.aboutUsTitle;
  }

  get content(): string {
    return HomepageStore.aboutUsContent;
  }

  get iataImage(): string {
    return HomepageStore.iataImage;
  }

  goToIata(): void {
    window.open('https://retailing.iata.org/armi/registry/easylinkz/', '_blank');
  }

}
